<template>
  <div class="login">
    <h1>Vui lòng đợi. Hệ thống đang kiểm tra thông tin đăng nhập</h1>
    <b-loading :is-full-page="true" v-model="isLoading" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StaffHelper from '@/modules/staff.js'
import '@/main.js'
const StaffService = new StaffHelper();
export default {
  name: "auth",
  data(){
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters(['Token'])
  },
  methods:{
    doCheckAuth:StaffService.doCheckAuth,
    Auth:StaffService.Auth,
  },
  mounted(){
    if(this.$route.query.token){
      this.doCheckAuth()
    }else{
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>

</style>